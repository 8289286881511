import { ConfigService, LocaleService } from '@assist/shared/data';
import { coreConfig } from '@assist/shared/core';
import { importProvidersFrom, inject, Injectable, LOCALE_ID, Type } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from './environments/environment';
import { provideCueAnalytics, withHeadersIntecceptor } from '@cue/analytics';
import { provideNavigationSettings } from '@cue/navigation-settings';
import { provideLocalization } from '@cue/translate';
import { localizations } from '@assist/shared/translations';
import { CALENDARS_CONFIGURATION, CalendarsService } from '@cue/calendars';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';

@Injectable()
export class KendoIntlService extends CldrIntlService {
  private readonly localeService = inject(LocaleService);

  override firstDay(localeId?: string): number {
    const intlFirstDay = this.localeService.getFirstDayOfWeek();
    return intlFirstDay === 'monday' ? 1 : 0;
  }
}

export const appConfig = (configService: ConfigService, calendarService: Type<CalendarsService>) => {
  return {
    providers: [
      {
        provide: IntlService,
        useClass: KendoIntlService,
      },
      {
        useFactory: (configService: ConfigService) => ({
          cachingEnabled: configService.value.cachingEnabled,
          ewsApiUrl: configService.value.apiURL,
          calendarApiUrl: configService.value.calendarURL,
        }),
        deps: [ConfigService],
        provide: CALENDARS_CONFIGURATION,
      },
      {
        provide: CalendarsService,
        useClass: calendarService,
      },
      coreConfig(configService).providers,
      provideNavigationSettings({
        configurationFactory: () => {
          return {
            design: configService.value.design,
          };
        },
      }),
      provideLocalization({
        localizations: localizations,
        translocoConfig: {
          availableLangs: configService.value.languages.filter((x) => x.activeInAssist).map((x) => x.code),
          defaultLang: configService.value.languages.find((x) => x.default).code,
          prodMode: false,
          reRenderOnLangChange: true,
          fallbackLang: configService.value.languages.find((x) => x.default).code,
          missingHandler: {
            useFallbackTranslation: true,
          },
        },
      }),
      provideCueAnalytics(
        withHeadersIntecceptor({
          apiDomains: () => {
            const configService = inject(ConfigService);
            return [configService.value.apiURL];
          },
          deviceIDHeaderName: 'deviceID',
          sessionHeaderName: 'sessionID',
        }),
      ),
      importProvidersFrom(
        ServiceWorkerModule.register('/ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerImmediately',
        }),
      ),
    ],
  };
};
